@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Typography  */

  /* TODO: Add XL screen vw based text sizes */

  --base-rem: 16;

  --text-3xl-size: 80;
  --text-3xl-size-rem: calc((var(--text-3xl-size) / var(--base-rem) * 1rem));
  --text-3xl-lineHeight: 80px;
  --text-3xl-letterSpacing: 0;

  --text-2xl-size: 60;
  --text-2xl-size-rem: calc((var(--text-2xl-size) / var(--base-rem) * 1rem));
  --text-2xl-lineHeight: 50px;
  --text-2xl-letterSpacing: 0;

  --text-xl-size: 40;
  --text-xl-size-rem: calc((var(--text-xl-size) / var(--base-rem) * 1rem));
  --text-xl-lineHeight: 24px;
  --text-xl-letterSpacing: 0;

  --text-lg-size: 30;
  --text-lg-size-rem: calc((var(--text-lg-size) / var(--base-rem) * 1rem));
  --text-lg-lineHeight: 100%;
  --text-lg-letterSpacing: 0;

  --text-md-size: 22;
  --text-md-size-rem: calc((var(--text-md-size) / var(--base-rem) * 1rem));
  --text-md-lineHeight: 100%;
  --text-md-letterSpacing: 0em;

  --text-base-size: 16;
  --text-base-size-rem: calc((var(--text-base-size) / var(--base-rem) * 1rem));
  --text-base-lineHeight: 20px;
  --text-base-letterSpacing: 0;

  --text-sm-size: 15;
  --text-sm-size-rem: calc((var(--text-sm-size) / var(--base-rem) * 1rem));
  --text-sm-lineHeight: 22px;
  --text-sm-letterSpacing: 0em;

  --text-xs-size: 12;
  --text-xs-size-rem: calc((var(--text-xs-size) / var(--base-rem) * 1rem));
  --text-xs-lineHeight: 14px;
  --text-xs-letterSpacing: 0em;

  --text-2xs-size: 10;
  --text-2xs-size-rem: calc((var(--text-2xs-size) / var(--base-rem) * 1rem));
  --text-2xs-lineHeight: 16px;
  --text-2xs-letterSpacing: 0.1em;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @apply font-mono uppercase text-skafar-dark-brown;
}

::-moz-selection {
  /* Code for Firefox */
}

::selection {
}

.label {
}

.triple-banner,
.wine-slider {
  --swiper-pagination-color: #c26d71;
  --swiper-pagination-bottom: 0px;
  --swiper-pagination-bullet-inactive-color: #c26d71;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-horizontal-gap: 6px;
}

section.timeline-section .slider-item::before {
  /* before:absolute before:w-2 before:h-2 before:bg-skafar-dark-brown before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 */
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;

  @apply bg-skafar-dark-brown w-4 h-4 top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full;
}

/* SKÁFÁR SPECIFIC */
.pulse::after {
  content: "";
  height: 80px;
  position: absolute;
  width: 1px;
  bottom: 0px;
  left: 0;
  background-color: white;
}

.pulse::after {
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    height: 0px;
    top: 0px;
    bottom: unset;
  }

  40% {
    height: 80px;
    top: 0px;
    bottom: unset;
  }

  55% {
    height: 80px;
    top: 0px;
    bottom: unset;
  }

  85% {
    height: 0px;
    bottom: 0;
    top: 80px;
  }

  100% {
    height: 0px;
    bottom: -80px;
    top: 80px;
  }
}
